import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatDateAndHour } from 'utils/dateManager';
import { capitalizeString } from 'utils/helpers';

import Datepicker from 'components/base/Datepicker';
import IdentifierSelector from 'components/base/IdentifierSelector';
import Input from 'components/base/Input';
import RadioButton from 'components/base/RadioButton';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Textarea from 'components/base/Textarea';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import useCrematory from 'hooks/useCrematory';
import useDeposit from 'hooks/useDeposit';
import useOven from 'hooks/useOven';
import useUsers from 'hooks/useUsers';
import { i18n } from 'i18n';
import { CrematoryType, IMasterDataDto } from 'models/MasterData';
import { SaveService } from 'models/MasterService';
import { DeathType, SaveRecord } from 'models/Record';
import { SimpleUser } from 'models/User';
import { RootState } from 'store';

type CremationProps = {
  className?: string;
  disabled?: boolean;
  formikProps: FormikProps<SaveService>;
  record?: SaveRecord;
};

const CremationForm: FC<CremationProps> = ({
  className,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
  record,
}) => {
  const [updatedAshes, setUpdatedAshes] = useState(false);
  const { t } = useTranslation();
  const { ashesDestinations, funeraryOrigins, owners } = useSelector(
    (state: RootState) => state.masterData,
  );
  const { normalized } = useSelector((state: RootState) => state);
  const { crematories, normalizedCrematories } = useCrematory(
    values.cremationServiceDto?.address?.city,
  );
  const { deposits, normalizedDeposits } = useDeposit(
    values.cremationServiceDto?.address?.city,
  );
  const { normalizedOvens, ovens } = useOven(
    values.cremationServiceDto?.crematorium,
    values.cremationServiceDto?.address?.city,
  );
  const {
    loading: loadingUsers,
    normalizedUsers,
    users,
  } = useUsers([SimpleUserRequestPermissionNames.ADVICE_ADD]);

  const familyOptions = [
    { label: i18n.t('common.yes'), value: 'yes' },
    { label: i18n.t('common.no'), value: 'no' },
  ];

  const getPresence = (val: string) => {
    if (val === familyOptions[0].value) {
      return true;
    }
    if (val === familyOptions[1].value) {
      return false;
    }
    return undefined;
  };

  useEffect(() => {
    if (
      values.cremationServiceDto
      && !values.cremationServiceDto?.ashesReceiver
      && !values.cremationServiceDto?.ashesReceiverCif
      && !values.cremationServiceDto?.ashesReceiverIdentifier
      && !values.cremationServiceDto?.ashesReceiverPassport
      && !values.cremationServiceDto?.ashesReceiverNie
      && !updatedAshes
    ) {
      setFieldValue(
        'cremationServiceDto.ashesReceiver',
        values.declarantData?.fullName,
      );
      setFieldValue(
        'cremationServiceDto.ashesReceiverCif',
        values.declarantData?.cif,
      );
      setFieldValue(
        'cremationServiceDto.ashesReceiverIdentifier',
        values.declarantData?.identifier,
      );
      setFieldValue(
        'cremationServiceDto.ashesReceiverPassport',
        values.declarantData?.passport,
      );
      setFieldValue(
        'cremationServiceDto.ashesReceiverNie',
        values.declarantData?.nie,
      );
      setUpdatedAshes(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.cremationServiceDto]);

  return (
    <div className={classNames('cremation-form', className)}>
      <fieldset>
        <legend>{t('service.INCINERACION')}</legend>
        <SelectCountryProvinceCity
          key="address"
          cityProps={{
            error: getIn(errors, 'cremationServiceDto.address.city'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.c',
            onBlur: handleBlur,
            onChange: (val: string) => {
              setFieldValue('cremationServiceDto.address.city', val);
              setFieldValue(
                'cremationServiceDto.address.crematorium',
                undefined,
              );
              setFieldValue('cremationServiceDto.address.deposit', undefined);
              setFieldValue('cremationServiceDto.address.oven', undefined);
            },
            placeholder: t('common.city'),
            value: values.cremationServiceDto?.address?.city || '',
          }}
          countryProps={{
            error: getIn(errors, 'cremationServiceDto.address.country'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('cremationServiceDto.address.country', val),
            placeholder: t('common.country'),
            value: values.cremationServiceDto?.address?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(errors, 'cremationServiceDto.address.province'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('cremationServiceDto.address.province', val),
            placeholder: t('common.province'),
            value: values.cremationServiceDto?.address?.province || '',
          }}
        />
        <Select<CrematoryType>
          key="crematorium"
          disabled={
            isSubmitting
            || disabled
            || !values.cremationServiceDto?.address?.city
          }
          error={getIn(errors, 'cremationServiceDto.crematorium')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="crematorium"
          options={crematories}
          placeholder={t('service.cremation.crematorium')}
          value={
            values.cremationServiceDto?.crematorium
              ? normalizedCrematories[values.cremationServiceDto?.crematorium]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => {
            setFieldValue('cremationServiceDto.crematorium', val?.code);
            setFieldValue('cremationServiceDto.address.oven', undefined);
            setFieldValue('cremationServiceDto.work', val?.work);
          }}
        />
        <Select<IMasterDataDto>
          key="oven"
          disabled={
            isSubmitting
            || disabled
            || !values.cremationServiceDto?.address?.city
            || !values.cremationServiceDto?.crematorium
          }
          error={getIn(errors, 'cremationServiceDto.oven')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="oven"
          options={ovens}
          placeholder={t('service.cremation.oven')}
          value={
            values.cremationServiceDto?.oven
              ? normalizedOvens[values.cremationServiceDto?.oven]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.oven', val?.code)}
        />
        <Select<IMasterDataDto>
          key="owner"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'cremationServiceDto.owner')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="owner"
          options={owners}
          placeholder={t('service.cremation.owner')}
          value={
            values.cremationServiceDto?.owner
              ? normalized.owners[values.cremationServiceDto?.owner]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.owner', val?.code)}
        />
        <Select<IMasterDataDto>
          key="deposit"
          disabled={
            isSubmitting
            || disabled
            || !values.cremationServiceDto?.address?.city
          }
          error={getIn(errors, 'cremationServiceDto.deposit')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="deposit"
          options={deposits}
          placeholder={t('service.deposit.deposit')}
          value={
            values.cremationServiceDto?.deposit
              ? normalizedDeposits[values.cremationServiceDto?.deposit]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.deposit', val?.code)}
        />
        <Datepicker
          key="entryDate"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'cremationServiceDto.entryDate')}
          minDate={
            record?.deathData?.deathDate && new Date(record.deathData.deathDate)
          }
          name="entryDate"
          placeholder={t('service.cremation.entryDate')}
          selected={values.cremationServiceDto?.entryDate}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.entryDate', val)}
        />
        <Datepicker
          key="entryTime"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'cremationServiceDto.entryTime')}
          name="entryTime"
          placeholder={t('service.cremation.entryTime')}
          selected={values.cremationServiceDto?.entryTime}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.entryTime', val)}
        />
        <Datepicker
          key="cremationDate"
          disabled={
            disabled || isSubmitting || !values.cremationServiceDto?.entryDate
          }
          error={getIn(errors, 'cremationServiceDto.cremationDate')}
          minDate={
            values.cremationServiceDto?.entryDate
            && new Date(values.cremationServiceDto.entryDate)
          }
          name="cremationDate"
          placeholder={t('service.cremation.cremationDate')}
          selected={values.cremationServiceDto?.cremationDate}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.cremationDate', val)}
        />
        <Datepicker
          key="cremationTime"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'cremationServiceDto.cremationTime')}
          name="cremationTime"
          placeholder={t('service.cremation.cremationTime')}
          selected={values.cremationServiceDto?.cremationTime}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.cremationTime', val)}
        />
        {/* TODO: esto deberia ser un selector de operarios */}
        <Input
          key="operator"
          error={getIn(errors, 'cremationServiceDto.operator')}
          name="operator"
          placeholder={t('service.cremation.operator')}
          type="text"
          value={values.cremationServiceDto?.operator || ''}
          disabled
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.operator', val)}
        />
        <Select<IMasterDataDto>
          key="originMortuary"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'cremationServiceDto.originMortuary')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="originMortuary"
          options={funeraryOrigins}
          placeholder={t('service.cremation.originMortuary')}
          value={
            values.cremationServiceDto?.originMortuary
              ? normalized.funeraryOrigins[
                values.cremationServiceDto?.originMortuary
              ]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.originMortuary', val?.code)}
        />
        <RadioButton
          checked={
            values.cremationServiceDto?.familyPresence === undefined
            || values.cremationServiceDto?.familyPresence === null
              ? ''
              : `${
                (values.cremationServiceDto?.familyPresence
                  ? familyOptions[0]
                  : familyOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'cremationServiceDto.familyPresence')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          name="cremationServiceDto.familyPresence"
          options={familyOptions}
          placeholder={t('service.cremation.familyPresence')}
          onChange={(val: string) => setFieldValue(
            'cremationServiceDto.familyPresence',
            getPresence(val),
          )}
        />
        <Input
          key="judicial"
          checked={record?.deathData?.deathType === DeathType.judicial}
          name="judicial"
          placeholder={t('service.cremation.judicial')}
          type="checkbox"
          disabled
          onBlur={handleBlur}
        />
        <Input
          key="zincBox"
          checked={!!values.cremationServiceDto?.zincBox}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'cremationServiceDto.zincBox')}
          name="zincBox"
          placeholder={t('service.cremation.zincBox')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.zincBox', val)}
        />
        <div>
          <Input
            key="documentation"
            checked={!!values.cremationServiceDto?.cremationDocumentation}
            disabled={isSubmitting || disabled}
            error={getIn(errors, 'cremationServiceDto.cremationDocumentation')}
            id="documentation"
            name="documentation"
            placeholder={t('service.cremation.documentationOkDetail')}
            style={{ whiteSpace: 'pre-line' }}
            type="checkbox"
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('cremationServiceDto.cremationDocumentation', val)}
          />
          <Trans i18nKey="multiline">
            {t('service.cremation.documentationOkDetail2')}
          </Trans>
        </div>
        <div>
          <div>
            <span>{`${t('service.cremation.documentationUser')}: ${values.cremationServiceDto?.cremationDocumentationUser || '-'}`}</span>
          </div>
          <div>
            <span>
              {`${t('service.cremation.documentationTime')}: ${values.cremationServiceDto?.cremationDocumentationDatetime
                ? formatDateAndHour(values.cremationServiceDto?.cremationDocumentationDatetime)
                : '-'}`}
            </span>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>{t('service.cremation.ashes')}</legend>
        <Select<IMasterDataDto>
          key="ashesDestiny"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'cremationServiceDto.ashesDestiny')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="ashesDestiny"
          options={ashesDestinations}
          placeholder={t('service.cremation.ashesDestiny')}
          value={
            values.cremationServiceDto?.ashesDestiny
              ? normalized.ashesDestinations[
                values.cremationServiceDto?.ashesDestiny
              ]
              : undefined
          }
          clearable
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.ashesDestiny', val?.code)}
        />
        <Datepicker
          key="ashesDeliveryDate"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'cremationServiceDto.ashesDeliveryDate')}
          name="ashesDeliveryDate"
          placeholder={t('service.cremation.ashesDeliveryDate')}
          selected={values.cremationServiceDto?.ashesDeliveryDate}
          clearable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.ashesDeliveryDate', val)}
        />
        <Select<SimpleUser>
          disabled={loadingUsers}
          error={getIn(errors, 'cremationServiceDto.ashesDeliveryHandler')}
          getLabel={(option) => `${option.name} ${option.firstSurname} ${
            option.secondSurname || ''
          }`}
          getValue={(option) => option.id}
          name="ashesDeliveryHandler"
          options={users}
          placeholder={t('service.cremation.ashesDeliveryHandler')}
          value={
            values.cremationServiceDto?.ashesDeliveryHandler
              ? normalizedUsers[
                values.cremationServiceDto?.ashesDeliveryHandler
              ]
              : undefined
          }
          clearable
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.ashesDeliveryHandler', val?.id)}
        />
        <Input
          key="ashesReceiver"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'cremationServiceDto.ashesReceiver')}
          name="ashesReceiver"
          placeholder={t('service.cremation.ashesReceiver')}
          type="text"
          value={values.cremationServiceDto?.ashesReceiver || ''}
          clearable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.ashesReceiver', val)}
        />
        <IdentifierSelector
          disabled={disabled || isSubmitting}
          errors={{
            cif: getIn(errors, 'cremationServiceDto.ashesReceiverCif'),
            identifier: getIn(
              errors,
              'cremationServiceDto.ashesReceiverIdentifier',
            ),
            nie: getIn(errors, 'cremationServiceDto.ashesReceiverNie'),
            passport: getIn(
              errors,
              'cremationServiceDto.ashesReceiverPassport',
            ),
          }}
          name="cremationServiceDto.identifier"
          placeholder={t('service.cremation.ashesReceiverIdentifier')}
          type="text"
          values={{
            cif: values.cremationServiceDto?.ashesReceiverCif,
            identifier: values.cremationServiceDto?.ashesReceiverIdentifier,
            nie: values.cremationServiceDto?.ashesReceiverNie,
            passport: values.cremationServiceDto?.ashesReceiverPassport,
          }}
          onBlur={handleBlur}
          onChange={(key, val) => {
            setFieldValue(
              `cremationServiceDto.ashesReceiver${capitalizeString(key)}`,
              val,
            );
          }}
        />
        <Textarea
          key="comment"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'cremationServiceDto.comment')}
          name="comment"
          placeholder={t('common.observations')}
          value={values.cremationServiceDto?.comment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('cremationServiceDto.comment', val)}
        />
      </fieldset>
    </div>
  );
};

export default CremationForm;
